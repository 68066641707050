<template>
    <div id="user_form" v-if="pageData">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0 ml-0 color4 font-weight-bolder">
          {{ pageData.id == 0 ? "Add" : "Edit" }} Page Information - {{ pageData.page_type.type }}
        </h3>
      </div>
  
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
            <b-row class="mt-2 mt-sm-3">
              <b-col md="12">
                <b-form-group label="Content" label-for="content" label-cols-md="1" class="required">
                  <validation-provider #default="{ errors }" name="content" rules="required">
                  <div class="quill-editor">
                    <quill-editor v-model="pageData.content" :content="pageData.content"
                      :options="editorOption" ref="QuillEditor">
                    </quill-editor>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
  
            <!-- Button: Submit & Reset Button.-->
  
            <hr />
            <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-sm-2 mobile-w100"
                type="submit">
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide"
                class="mt-1 mt-sm-0 mobile-w100">
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { ref, onMounted } from "@vue/composition-api";
  import vSelect from "vue-select";
  import store from "@/store";
  import Ripple from "vue-ripple-directive";
  import "animate.css";
  import { heightTransition } from "@core/mixins/ui/transition";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import formValidation from "@core/comp-functions/forms/form-validation";
  import { required, alphaNum, email } from "@validations";
  import "quill/dist/quill.core.css";
  // eslint-disable-next-line
  import "quill/dist/quill.snow.css";
  // eslint-disable-next-line
  import "quill/dist/quill.bubble.css";
  import { quillEditor } from "vue-quill-editor";
  
  export default {
    directives: {
      Ripple,
    },
    mixins: [heightTransition],
    components: {
      BRow,
      BCol,
      BForm,
      BOverlay,
      BFormGroup,
      flatPickr,
      BFormInput,
      vSelect,
      BFormRadioGroup,
      BFormCheckboxGroup,
      BButton,
      BFormCheckbox,
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback,
      quillEditor,
    },
    directives: {
      Ripple,
    },
    methods: {
      hide() {
        this.$router.replace("/pages/list");
      },
      onSubmit() {
        console.log("submit data", this.pageData);
        this.$swal({
          title: "Are you sure?",
          showCancelButton: true,
          confirmButtonText: "Save Changes",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            store
              .dispatch("app-pages/addPage", this.pageData)
              .then((response) => {
                this.loading = false;
  
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$router.replace("/pages/list");
              })
              .catch((error) => {
                this.loading = false;
  
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    props: {
      pageData: {
        type: Object,
        required: true,
      },
    },
    setup(props, { emit }) {
      console.log(props.pageData);
      const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);
  
      const headerObj = {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      };
  
      const loading = ref(false);
  
      const resetData = () => {
        emit("refetch-data");
      };
      const resetpageData = () => {
        props.pageData = JSON.parse(JSON.stringify({}));
      };
      const { refFormObserver, getValidationState, resetForm } = formValidation(
        resetpageData
      );
  
      onMounted(() => { });
  
      const editorOption = {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "bullet" }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ["link"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ],
            handlers: {
              image: (value) => {
                console.log("image", value);
                if (value) {
                  document.querySelector(".avatar-uploader input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      };
  
      return {
        loading,
        resetData,
        refFormObserver,
        getValidationState,
        resetForm,
        resetpageData,
        uploadUrl,
        headerObj,
        editorOption,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  
  .quill-editor {
    .ql-container {
      min-height: 200px;  // Adjust this value as needed
    }
    
    .ql-editor {
      min-height: 200px;  // Should match container min-height
    }
  }
  </style>
  