export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    action: "read",
    resource: "Auth",
  },
  {
    title: "Banner",
    route: "",
    icon: "SidebarIcon",
    resource: "Auth",
    action: "read",
    children: [
      {
        title: "Banner Categories",
        route: "banner-category-list",
        resource: "Auth",
        action: "read",
      },
      {
        title: "Banners",
        route: "banner-list",
        resource: "Auth",
        action: "read",
      },
    ],
  },
  {
    title: "Product",
    route: "",
    icon: "ShoppingBagIcon",
    resource: "Auth",
    action: "read",
    children: [
      {
        title: "Product Categories",
        route: "product-category-list",
        resource: "Auth",
        action: "read",
      },
      {
        title: "Products",
        route: "product-list",
        resource: "Auth",
        action: "read",
      },
    ],
  },
  {
    title: "Customer",
    route: "",
    icon: "UsersIcon",
    resource: "Auth",
    action: "read",
    children: [
      {
        title: "Customer Account",
        route: "clients-list",
        resource: "Auth",
        action: "read",
      },
    ],
  },

  {
    title: "User",
    route: "",
    icon: "UsersIcon",
    resource: "Auth",
    action: "read",
    children: [
      {
        title: "By User",
        route: "users-list",
        resource: "Auth",
        action: "read",
      },
    ],
  },

  // {
  //   title: "Permissions",
  //   route: "",
  //   icon: "LockIcon",
  //   resource: "Auth",
  //   action: "read",
  //   children: [
  //     {
  //       title: "Access Right",
  //       route: "staff-roles-list",
  //       resource: "Auth",
  //       action: "read",
  //     },
  //     {
  //       title: "Role Permission",
  //       route: "roles-list",
  //       resource: "Auth",
  //       action: "read",
  //     },
  //   ],
  // },

  {
    title: "Pages",
    route: "pages-list",
    icon: "FileTextIcon",
    resource: "Auth",
    action: "read",
  },

  {
    title: "Setting",
    route: "",
    icon: "SettingsIcon",
    resource: "Auth",
    action: "read",
    children: [
      {
        title: "Faq",
        route: "faq-list",
        resource: "Auth",
        action: "read",
      },
      {
        title: "Setting",
        route: "settings-edit",
        resource: "Auth",
        action: "read",
      },
      {
        title: "Sticky Bar",
        route: "stickybar-list",
        resource: "Auth",
        action: "read",
      },
      {
        title: "Product Variants",
        route: "",
        resource: "Auth",
        action: "read",
        children: [
          {
            title: "Color",
            route: "color-list",
            resource: "Auth",
            action: "read",
          },
          {
            title: "Size",
            route: "size-list",
            resource: "Auth",
            action: "read",
          },
          {
            title: "Type",
            route: "type-list",
            resource: "Auth",
            action: "read",
          },
        ],
      },
    ],
  },
];
