<template>
  <div>
    <sticky-bar-list-add-new
      :is-add-new-sticky-bar-sidebar-active.sync="
        isAddNewStickyBarSidebarActive
      "
      :id="id"
      :data="stickyBarData"
      @refetch-data="refetchData"
      v-if="ability.can('create', 'stickybars') || true"
    >
    </sticky-bar-list-add-new>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">
            Sticky Bar
          </h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search ..."
            />
          </div>
          <b-button
            variant="primary"
            class="px-3 mobile-w100"
            @click="handleCreate"
          >
            <span class="text-nowrap"
              ><feather-icon size="14" icon="PlusIcon" /> Sticky Bar</span
            >
          </b-button>
        </div>
      </div>

      <b-table
        ref="refStickyBarTable"
        class="position-relative"
        :items="fetchStickyBar"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Has Day Range -->
        <template #cell(has_day_range)="data">
          <div
            v-if="data.item.has_day_range == 1"
            size="18"
            class="mr-50 text-success"
          >
            {{ data.item.start_date }} - {{ data.item.end_date }}
          </div>
          <feather-icon
            v-else
            icon="XIcon"
            size="18"
            class="mr-50 text-danger"
          />
        </template>

        <template #cell(is_active)="data">
          <b-col cols="12">
            <b-form-checkbox :checked="data.item.is_active ? true : false" @change="updateStatus(data.item.id)"
              class="custom-control-success" name="check-button" switch inline />
          </b-col>
        </template>

        <!-- Column: Content -->
        <template #cell(content)="data">
          <b-badge
            href="#"
            class="d-block"
            v-bind:style="{
              'background-color': data.item.background_color,
              'color': data.item.text_color,
              'font-weight': data.item.is_bold == 1 ? '900' : '400',
            }"
          >
            {{ data.item.content }}
          </b-badge>
        </template>

        <!-- Column: Background Color -->
        <template #cell(background_color)="data">
          <b-badge
            href="#"
            class="d-block"
            v-bind:style="{
              'background-color': data.item.background_color,
            }"
          >
            {{ data.item.background_color }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-dropdown
            class="action-trigger-btn"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalStickyBar"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import StickyBarStoreModule from "../stickybarStoreModule";
import useStickyBarList from "./useStickyBarList";
import StickyBarListAddNew from "./StickyBarListAddNew.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    StickyBarListAddNew,
    BFormCheckbox,
  },
  methods: {
    updateStatus(id) {
      this.loading = true;
      store
        .dispatch("stickybar/updateStatus", id)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the document type.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("stickybar/deleteStickyBar", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleCreate() {
      this.id = 0;
      this.resetStickyBarData();
      this.isAddNewStickyBarSidebarActive = true;
    },
    handleEdit(item) {
      this.id = item.id;
      this.stickyBarData = { ...item };
      this.isAddNewStickyBarSidebarActive = true;
    },
    resetStickyBarData() {
      this.stickyBarData = {
        id: 0,
        content: "",
        background_color: "",
        text_color: "",
        is_active: 1,
        has_day_range: 0,
        start_date: "",
        end_date: "",
      };
    },
  },
  data() {
    return {
      id: 0,
      stickyBarData: {},
    };
  },
  setup() {
    const SETTING_STICKY_BAR_STORE_MODULE_NAME = "stickybar";

    if (!store.hasModule(SETTING_STICKY_BAR_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_STICKY_BAR_STORE_MODULE_NAME,
        StickyBarStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(SETTING_STICKY_BAR_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_STICKY_BAR_STORE_MODULE_NAME);
    });

    const isAddNewStickyBarSidebarActive = ref(false);

    const {
      fetchStickyBar,
      tableColumns,
      perPage,
      currentPage,
      totalStickyBar,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStickyBarTable,
      refetchData,
      ability,
    } = useStickyBarList();

    return {
      //Sidebar
      isAddNewStickyBarSidebarActive,

      fetchStickyBar,
      tableColumns,
      perPage,
      currentPage,
      totalStickyBar,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStickyBarTable,
      refetchData,
      avatarText,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
