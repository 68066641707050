<template>
  <component :is="productData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="productData === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No product found with this product id. Check
        <b-link class="alert-link" :to="{ name: 'product-list' }">
          Product List
        </b-link>
        for other products.
      </div>
    </b-alert>
    <product-edit-tab-information
      :product-data="productData"
      :color-options="colorOptions"
      :size-options="sizeOptions"
      :type-options="typeOptions"
      :product-category-options="productCategoryOptions"
      :brand-options="brandOptions"
      :unit-options="unitOptions"
      :warranty-options="warrantyOptions"
      @refetch-data="refetchData"
      @refetch-option="refetchOption"
      class="m-sm-2"
    />
  </component>
</template>

<script>
import { BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import productStoreModule from "../productStoreModule";
import productColorStoreModule from "../../setting/productvariant/color/colorStoreModule";
import ProductEditTabInformation from "./ProductEditTabInformation.vue";

export default {
  components: {
    ProductEditTabInformation,
    BCard,
    BAlert,
    BLink,
  },
  setup() {
    const productData = ref({});

    const PRODUCT_APP_STORE_MODULE_NAME = "app-product";
    const PRODUCT_COLOR_APP_STORE_MODULE_NAME = "product-color";
    const today = new Date();
    const year = today.getFullYear();

    const product = {
      id: 0,
      brand_id: null,
      unit_id: 1,
      name: "",
      cover_image_path: "",
      sku: "",
      path: "",
      product_categories: [],
      price: "",
      product_variant: [
        {
          id: 0,
          color_id: null,
          size_id: null,
          type_id: null,
          stock: 0,
          media_path: [],
        },
      ],
      description: "",
      highlight: "",
      shipping: "",
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
      is_dangerous: 0,
      is_active: 1,
      has_warranty: 0,
      warranty_type_id: null,
      warranty_period: null,
      warranty_policy: null,
      box_detail: null,
    };

    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule);

    if (!store.hasModule(PRODUCT_COLOR_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_COLOR_APP_STORE_MODULE_NAME, productColorStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME);
      if (store.hasModule(PRODUCT_COLOR_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_COLOR_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "product-create") {
        console.log("product", product);
        productData.value = product;
      } else {
        store
          .dispatch("app-product/fetchProduct", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            productData.value = response.data.product;
            const categories = response.data.product.product_categories;

            // Check if the first element is an object or a number
            if (
              categories.length > 0 &&
              typeof categories[0] === "object" &&
              categories[0] !== null
            ) {
              // If it's an object, map to get the ids
              productData.value.product_categories = categories.map(
                (category) => category.id
              );
            } else {
              // If it's already an array of ids, use it as is
              productData.value.product_categories = categories;
            }
          })
          .catch((error) => {
            console.log("error when fetching product", error);
            if (error.response.status === 404) {
              productData.value = undefined;
            }
          });
      }
    };

    onMounted(() => {
      refetchData();
      refetchOption();
    });

    const colorOptions = ref([]);
    const sizeOptions = ref([]);
    const typeOptions = ref([]);
    const productCategoryOptions = ref([]);
    const brandOptions = ref([]);
    const unitOptions = ref([]);
    const warrantyOptions = ref([]);

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          colors: true,
          sizes: true,
          types: true,
          product_categories: true,
          brands: true,
          units: true,
          warranty_types: true,
        })
        .then((response) => {
          console.log(response);
          colorOptions.value = response.data.colors;
          sizeOptions.value = response.data.sizes;
          typeOptions.value = response.data.types;
          brandOptions.value = response.data.brands;
          unitOptions.value = response.data.units;
          warrantyOptions.value = response.data.warranty_types;
          // productCategoryOptions.value = response.data.product_categories;
          const categories = response.data.product_categories;

          // Build a map of categories by id
          const categoryMap = categories.reduce((acc, category) => {
            acc[category.id] = category;
            return acc;
          }, {});

          // Function to get full path of a category
          function getCategoryPath(category) {
            const path = [];
            let currentCategory = category;

            while (currentCategory) {
              path.unshift(currentCategory.name);
              currentCategory = categoryMap[currentCategory.parent_id];
            }

            return path.join(" > ");
          }

          // Format options for v-select
          productCategoryOptions.value = categories.map((category) => ({
            id: category.id,
            name: getCategoryPath(category),
          }));
        });
    };

    return {
      productData,
      sizeOptions,
      colorOptions,
      typeOptions,
      productCategoryOptions,
      brandOptions,
      unitOptions,
      warrantyOptions,
      refetchData,
      refetchOption,
    };
  },
};
</script>
