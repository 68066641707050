import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import pages from "./routes/pages";
import users from "./routes/users";
import roles from "./routes/roles";
import email from "./routes/email";
import clients from "./routes/clients";
import documents from "./routes/documents";
import settings from "./routes/settings";
import todolist from "./routes/todolist";
import products from "./routes/products";
import banners from "./routes/banners";
import stickybars from "./routes/stickybars";
import info from "./routes/info";
// import { email } from 'vee-validate/dist/rules'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...email,
    ...pages,
    ...users,
    ...roles,
    ...clients,
    ...documents,
    ...settings,
    ...todolist,
    ...products,
    ...banners,
    ...stickybars,
    ...info,
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: "auth-login" });

    // If logged in => not authorized
    return next({ name: "not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser());
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
