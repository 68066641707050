import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/pages/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPage(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/pages`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPage(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/pages", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
