<template>
  <b-sidebar id="add-new-sticky-bar-sidebar" :visible="isAddNewStickyBarSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sticky-bar-sidebar-active', val)
      ">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ button_text }} Sticky Bar</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Content-->
            <validation-provider #default="validationContext" name="Content" rules="required">
              <b-form-group label="Content" label-for="content">
                <b-form-input id="content" v-model="stickyBarData.content" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="Content" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Background Color -->
            <validation-provider #default="validationContext" name="Background Color" rules="required">
              <b-form-group label="Background Color" label-for="background_color">
                <b-input-group>
                  <b-form-input id="background_color" v-model="stickyBarData.background_color" autofocus
                    :state="getValidationState(validationContext)" trim />
                  <b-input-group-append is-text>
                    <el-color-picker v-model="stickyBarData.background_color" size="mini"></el-color-picker>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Text Color -->
            <validation-provider #default="validationContext" name="Text Color" rules="required">
              <b-form-group label="Text Color" label-for="text_color">
                <b-input-group>
                  <b-form-input id="text_color" v-model="stickyBarData.text_color" autofocus
                    :state="getValidationState(validationContext)" trim />
                  <b-input-group-append is-text>
                    <el-color-picker v-model="stickyBarData.text_color" size="mini"></el-color-picker>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-col cols="12">
              <b-form-group label-for="has_day_range">
                <validation-provider #default="{ errors }" name="has_day_range" rules="required">
                  <b-form-checkbox :checked="stickyBarData.has_day_range ? true : false" @change="
                    stickyBarData.has_day_range = !stickyBarData.has_day_range
                    " class="custom-control-success" name="check-button" switch inline />
                  {{
                    stickyBarData.has_day_range
                      ? "Promotion Sticky Bar"
                      : "Promotion Sticky Bar"
                  }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Field: Start Date -->
            <b-col cols="12" md="12" v-if="stickyBarData.has_day_range">
              <validation-provider #default="validationContext" name="Promotion Start Date" rules="required">
                <b-form-group label-cols-sm="6" label-cols="12" class="required" label="Promotion Start Date"
                  label-for="start_date">
                  <flat-pickr v-model="stickyBarData.start_date" class="form-control" :config="futureDateConfig"
                    placeholder="YYYY-MM-DD" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: End Date -->
            <b-col cols="12" md="12" v-if="stickyBarData.has_day_range">
              <validation-provider #default="validationContext" name="Promotion End Date" rules="required">
                <b-form-group label-cols-sm="6" label-cols="12" class="required" label="Promotion End Date"
                  label-for="start_date">
                  <flat-pickr v-model="stickyBarData.end_date" class="form-control" :config="futureDateConfig"
                    placeholder="YYYY-MM-DD" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols="12" md="12">
              <b-form-group label-for="is_active">
                <b-form-checkbox :checked="stickyBarData.is_active ? true : false" @change="
                  stickyBarData.is_active = !stickyBarData.is_active
                  " class="custom-control-success" name="check-button" switch inline />
                {{ stickyBarData.is_active ? "Is Active" : "Is Active" }}
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <b-form-group label-for="is_bold">
                <b-form-checkbox :checked="stickyBarData.is_bold ? true : false" @change="
                  stickyBarData.is_bold = !stickyBarData.is_bold
                  " class="custom-control-success" name="check-button" switch inline />
                {{ stickyBarData.is_bold ? "Bold" : "Bold" }}
              </b-form-group>
            </b-col>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
                <!-- {{ button_text }} -->
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>

          <!-- add how the content will be displayed -->
          <b-col cols="12">
            <b-badge href="#" class="d-block" v-bind:style="{
              'background-color': stickyBarData.background_color,
              'color': stickyBarData.text_color,
              'font-weight': stickyBarData.is_bold ? '900' : '400',
            }">
              {{ stickyBarData.content }}
            </b-badge>
          </b-col>

        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BCol,
  BBadge,
  BInputGroupAppend,
  BInputGroup
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch, onMounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BCol,
    flatPickr,
    BBadge,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewStickyBarSidebarActive",
    event: "update:is-add-new-sticky-bar-sidebar-active",
  },
  props: {
    isAddNewStickyBarSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  methods: {},
  setup(props, { emit }) {
    console.log(props);
    const loading = ref(false);
    const blankStickyBarData = {
      id: 0,
      content: null,
      has_day_range: 0,
      start_date: null,
      end_date: null,
      is_active: 1,
    };
    const button_text = ref("Add");

    const stickyBarData = ref(
      JSON.parse(JSON.stringify(blankStickyBarData))
    );

    const resetStickyBarData = () => {
      stickyBarData.value = JSON.parse(
        JSON.stringify(blankStickyBarData)
      );
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewStickyBarSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewStickyBarSidebarActive) {
          stickyBarData.value = { ...props.data };

          if (stickyBarData.value.id > 0) {
            button_text.value = "Edit";
          } else {
            button_text.value = "Add";
          }
        } else {
          button_text.value = "Add";
        }
      }
    );

    const onSubmit = () => {
      console.log(stickyBarData);
      loading.value = true;
      store
        .dispatch("stickybar/updateStickyBar", stickyBarData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-sticky-bar-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetStickyBarData
    );

    onMounted(() => { });

    return {
      stickyBarData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-sticky-bar-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
