<template>
    <component :is="pageData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="pageData === undefined">
            <h4 class="alert-heading">Error fetching page data</h4>
            <div class="alert-body">
                No page found with this page id. Check
                <b-link class="alert-link" :to="{ name: 'pages-list' }">
                    Page List
                </b-link>
                for other pages.
            </div>
        </b-alert>

        <b-tabs v-if="pageData" class="tabs-primary" pills>
            <!-- Tab: Information -->
            <b-tab active>
                <template #title>
                    <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
                    <span class="d-none d-sm-inline">Page</span>
                </template>
                <pages-tab-information :page-data="pageData" @refetch-data="refetchData" class="mt-2 pt-75" />
            </b-tab>
        </b-tabs>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import pagesStoreModule from "../pagesStoreModule";
import PagesTabInformation from "./PagesTabInformation.vue";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        PagesTabInformation,
    },
    setup() {
        const pageData = ref(null);
        const PAGE_APP_STORE_MODULE_NAME = "app-pages";

        // Register module
        if (!store.hasModule(PAGE_APP_STORE_MODULE_NAME))
            store.registerModule(
                PAGE_APP_STORE_MODULE_NAME,
                pagesStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(PAGE_APP_STORE_MODULE_NAME))
                store.unregisterModule(PAGE_APP_STORE_MODULE_NAME);
        });

        const refetchData = () => {
            store
                .dispatch("app-pages/fetchPage", {
                    id: router.currentRoute.params.id,
                })
                .then((response) => {
                    pageData.value = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        pageData.value = undefined;
                    }
                });
        };

        onMounted(() => {
            refetchData();
        });

        return {
            pageData,
            refetchData,
        };
    },
};
</script>

<style></style>