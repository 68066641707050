<template>
    <div>
      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
            <h3 class="font-weight-bolder text-nowrap mb-0">Page</h3>
            <div class="form-col-select ml-2">
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <feather-icon size="18" icon="ChevronDownIcon" />
            </div>
            <div class="search-primary w-100">
              <feather-icon size="16" icon="SearchIcon" />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Find Page"
              />
            </div>
          </div>
        </div>
  
        <b-table
          ref="refPageListTable"
          class="position-relative"
          :items="fetchPages"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(content)="data">
            <div v-html="data.item.content"></div>
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              class="action-trigger-btn"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
  
              <b-dropdown-item
                :to="{ name: 'pages-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalPages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
    BFormCheckbox,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import store from "@/store";
  import { ref, onUnmounted } from "@vue/composition-api";
  import { avatarText } from "@core/utils/filter";
  import usePagesList from "./usePagesList";
  import pagesStoreModule from "../pagesStoreModule";
  import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
  import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
  import Ripple from "vue-ripple-directive";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
  
      vSelect,
      AppTimeline,
      AppTimelineItem,
      VBModal,
      BFormGroup,
      BForm,
      BOverlay,
      BFormCheckbox,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    data() {
      return {
      };
    },
    setup() {
      const PAGE_APP_STORE_MODULE_NAME = "app-pages";
  
      // Register module
      if (!store.hasModule(PAGE_APP_STORE_MODULE_NAME))
        store.registerModule(
          PAGE_APP_STORE_MODULE_NAME,
          pagesStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(PAGE_APP_STORE_MODULE_NAME))
          store.unregisterModule(PAGE_APP_STORE_MODULE_NAME);
      });
  
      const {
        fetchPages,
        tableColumns,
        perPage,
        currentPage,
        totalPages,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPageListTable,
        refetchData,
  
        ability,
      } = usePagesList();
  
      return {
  
        fetchPages,
        tableColumns,
        perPage,
        currentPage,
        totalPages,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPageListTable,
        refetchData,
        ability,
  
        // Filter
        avatarText,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  