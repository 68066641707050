export default [
    // Pages
    {
        path: "/pages/list",
        name: "pages-list",
        component: () => import("@/views/pages/info/pages-list/PagesList.vue"),
        meta: {
            action: "read",
            resource: "Auth",
        },
    },
    {
        path: "/pages/create",
        name: "pages-create",
        component: () => import("@/views/pages/info/pages-edit/PagesEdit.vue"),
        meta: {
            action: "read",
            resource: "Auth",
        },
    },
    {
        path: "/pages/edit/:id",
        name: "pages-edit",
        component: () => import("@/views/pages/info/pages-edit/PagesEdit.vue"),
        meta: {
            action: "read",
            resource: "Auth",
        },
    },
];
